import React from "react";
import Faq from "../components/faq/Faq";
import ContactComp from "../components/contact/ContactComp";
import { ImageBg } from "../components/comp-utils/CompUtils";
import "../components/land-about/land-about.css";
import "../components/game-compete/game-compete.css";
import AboutComp from "../components/about-comp/AboutComp";
import { motion } from "framer-motion";
import splitStringRegex from "../utils/stringRegex";

const About = () => {
  const headerText1 = "What is Masterslive";
  const headerText2 = "Network?";

  const header1Chars = splitStringRegex(headerText1);
  const header2Chars = splitStringRegex(headerText2);

  const charVariants = {
    hidden: { opacity: 0 },
    reveal: { opacity: 1 },
  };
  return (
    <div>
      <ImageBg className={"about-head"}>
        <div className="hero-gradient about-gradient"></div>
        <motion.h2
          initial="hidden"
          whileInView={"reveal"}
          transition={{ staggerChildren: 0.02 }}
        >
          {header1Chars.map((char, idx) => (
            <motion.span
              key={idx}
              transition={{ duration: 0.4 }}
              variants={charVariants}
              style={{willChange: "auto"}}
            >
              {char}
            </motion.span>
          ))}{" "}
          <br />{" "}
          {header2Chars.map((char, idx) => (
            <motion.span
              key={idx}
              transition={{ duration: 0.4 }}
              variants={charVariants}
              style={{willChange: "auto"}}
            >
              {char}
            </motion.span>
          ))}
        </motion.h2>
      </ImageBg>
      <AboutComp />
      <Faq />
      <ContactComp />
    </div>
  );
};

export default About;
