import React from "react";
import "./game-compete.css";
import { Button } from "../comp-utils/CompUtils";
import GameCard from "./GameCard";
import sports from "../../assets/images/sports.png";
import casino from "../../assets/images/casino.png";
// import lottery from "../../assets/images/lottery.png";
// import punta from "../../assets/images/punta.png";
import { motion } from "framer-motion";
import splitStringRegex from "../../utils/stringRegex";
import { useNavigate } from "react-router-dom";

const GameCompete = ({ product }) => {
  const headerText1 = "your Playground... your Rules!";

  const header1Chars = splitStringRegex(headerText1);

  const charVariants = {
    hidden: { opacity: 0 },
    reveal: { opacity: 1 },
  };

  const navigate = useNavigate();

  const goToProduct = () => {
    navigate("/products");
  };

  const gameCard = [
    {
      pic: sports,
      title: "Sports betting",
      text: "Join MeridianBetMasters for top-quality sports betting and unbeatable odds! Bet now for your chance to win big!",
      btnText: "Start playing",
      link: "https://meridianbet.ng/en/betting"
    },
    {
        pic: casino,
        title: "Online Casino",
        text: "Join MeridianBetMasters and dive into the thrilling experience of casino games with the chance to win big!",
        btnText: "Start playing",
        link: "https://meridianbet.ng/en/online-casino"
    },
    // {
    //     pic: lottery,
    //     title: "Lottery",
    //     text: "Lorem ipsum dolor sit amet consectetur. Bibendum lobortis vitae leo",
    //     btnText: "Start playing"
    // },
    // {
    //     pic: punta,
    //     title: "Punter loans",
    //     text: "Lorem ipsum dolor sit amet consectetur. Bibendum lobortis vitae leo",
    //     btnText: "Start playing"
    // },
  ];

  return (
    <div className="game-compete">
      {!product && <div className="game-gradient"></div>}
      {!product && (
        <div className="game-compete_head">
          <motion.h1
            initial="hidden"
            whileInView={"reveal"}
            transition={{ staggerChildren: 0.02 }}
          >
            {header1Chars.map((char, idx) => (
              <motion.span
                key={idx}
                transition={{ duration: 0.5 }}
                variants={charVariants}
                style={{willChange: "auto"}}
              >
                {char}
              </motion.span>
            ))}
          </motion.h1>
          <Button
            btnClass={"btn-sec"}
            btnText={"See all games"}
            clickBtn={goToProduct}
          />
        </div>
      )}
      <div className="game-card_cont">
        {gameCard?.map((item, idx) => {
          return (
            <GameCard
              key={idx}
              pic={item?.pic}
              title={item?.title}
              text={item?.text}
              btnText={item?.btnText}
              btnLink={item?.link}
            />
          );
        })}
      </div>
    </div>
  );
};

export default GameCompete;
