import React, { useState } from "react";
import "./contact-comp.css";
import instagram from "../../assets/images/instagram.png";
import twitter from "../../assets/images/twitter.png";
import linkedin from "../../assets/images/linkedin.png";
import { Button, Input, TextArea } from "../comp-utils/CompUtils";
import { motion } from "framer-motion";
import phone from "../../assets/images/phone.png";
import mail from "../../assets/images/mail.png";

const ContactComp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    messageBody: "",
  });

  const handleChange = (e) => {
    var newData = { ...formData };
    newData[e.target.id] = e.target.value;
    setFormData(newData);
    // console.log(formData);
  };

  const generateMailtoLink = () => {
    const { name, email, messageBody } = formData;
    const mailtoLink = `mailto:support@masterslivenetwork.com?subject=Message from ${name} (${email})&body=${encodeURIComponent(
      messageBody
    )}`;

    // Create a temporary anchor element to trigger the mailto
    const a = document.createElement("a");
    a.href = mailtoLink;
    a.click();
  };

  return (
    <>
    <div className="contact-cont">
      <div className="contact-left">
        <div className="c-left_head">
          <h2>Contact us</h2>
          <p>We’d love to hear from you! Our team is here to help</p>
        </div>
        <div className="c-left_socials">
          <div className="c-left_email">
            <img src={phone} alt="phone" />
            <a href="tel:+2347074702077">
              <h4>07074702077</h4>
            </a>
          </div>
          <div className="c-left_email">
            <img src={mail} alt="mail" style={{ width: "25px" }} />
            <h4>support@masterslivenetwork.com</h4>
          </div>
          <div className="c-l_social-links">
            {/* <p>On social media</p> */}
            <div className="c-l_socials_icons">
              <motion.button whileTap={{ scale: 0.85 }}>
                <a
                  href="https://www.instagram.com/masterslivenetwork/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagram} alt="instagram" />
                </a>
              </motion.button>
              <motion.button whileTap={{ scale: 0.85 }}>
                <a
                  href="https://x.com/Masterslivenet"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitter} alt="twitter" />
                </a>
              </motion.button>
              <motion.button whileTap={{ scale: 0.85 }}>
                <a href="https://www.linkedin.com/company/masterslive-network/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                  <img src={linkedin} alt="linkedin" />
                </a>
              </motion.button>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-right">
        <Input
          type={"email"}
          placeholder={"name@gmail.com"}
          className={"input-width"}
          id={"email"}
          value={formData.email}
          onChange={handleChange}
        />
        <Input
          type={"text"}
          placeholder={"Daniel Iyanu"}
          className={"input-width"}
          id={"name"}
          value={formData.name}
          onChange={handleChange}
        />
        <TextArea
          placeholder={"Enter your message"}
          className={"input-width"}
          id={"messageBody"}
          value={formData.messageBody}
          onChange={handleChange}
        />
        <Button
          btnClass={"btn contact-btn"}
          btnText={"Send message"}
          clickBtn={generateMailtoLink}
        />
      </div>
      <div className="contact-gradient"></div>
    </div>
    <p style={{display: "flex", justifyContent: "center", padding: "0 20px", textAlign: 'center'}}>&copy; 2024 MastersLive Network. All rights reserved.</p>
    </>
  );
};

export default ContactComp;
